import React from "react"
import Layout from "../../components/UI/layout"
import { StaticImage } from "gatsby-plugin-image"

const people = [
  {
    name: "Christopher Schaumloeffel",
    role: "Founder",
    imageUrl: "./cs_profile.jpg",
    bio: "I came late to the party by buying my first Bitcoins during the bull-run 2017. Since then, I've been obsessed with the crypto universe. I started The Simple Crypto Fund to apply my traditional finance skills as an experienced portfolio manager (CFA etc.) in this space. I'm convinced that simple but effective portfolio construction can significantly improve long-term returns in this asset class. I want to share my work and ideas with others as I'm deeply convinced that great results can only be achieved together. I live in Frankfurt, Germany together with my wonderful wife and our baby.",

    twitterUrl: "https://twitter.com/cschauml",
    linkedinUrl:
      "https://www.linkedin.com/in/christopher-schaumloeffel-cfa-1990b1110/",
    email: "mailto:cschaumloeffel@outlook.com",
  },
]

const About = () => {
  return (
    <Layout>
      <div className="mx-auto py-12 px-4 max-w-7xl lg:px-0 lg:py-24">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              Meet the team
            </h2>
            <p className="text-xl ">
              We're glad to see you're interested in the people behind The
              Simple Crypto Fund. This is us: People like you and me with an
              unstoppable interest in this fascinating new world. Please reach
              out if you have questions, feedback or just want to get in touch!
            </p>
          </div>
          <div className="lg:col-span-2">
            <ul
              role="list"
              className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0"
            >
              {people.map(person => (
                <li key={person.name} className="sm:py-8 list-none">
                  <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                    <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                      <StaticImage
                        className="object-cover shadow-lg rounded-lg"
                        src="../../../content/assets/images/cs_profile.jpg"
                        alt="profile_picture"
                        placeholder="blurred"
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <div className="space-y-4">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h2>{person.name}</h2>
                          <div className="flex flex-row">
                            <p className="text-cyan-600 mb-0">{person.role}</p>
                            <ul role="list" className="flex space-x-5 ml-2">
                              <li className="list-none">
                                <a
                                  href={person.twitterUrl}
                                  className="text-gray-400 hover:text-gray-500"
                                >
                                  <span className="sr-only">Twitter</span>
                                  <svg
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                  </svg>
                                </a>
                              </li>
                              <li className="list-none">
                                <a
                                  href={person.linkedinUrl}
                                  className="text-gray-400 hover:text-gray-500"
                                >
                                  <span className="sr-only">LinkedIn</span>
                                  <svg
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </a>
                              </li>
                              <li className="list-none">
                                <a
                                  href={person.email}
                                  className="text-gray-400 hover:text-gray-500"
                                >
                                  <span className="sr-only">Email</span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                  </svg>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-200">{person.bio}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
